
function Footer() {
    return (
        <footer class="footer">
            {/* <div class="d-sm-flex justify-content-center justify-content-sm-between">
                <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021.  Premium <a href="https://www.bootstrapdash.com/" target="_blank">Bootstrap admin template</a> from BootstrapDash. All rights reserved.</span>
                <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i class="ti-heart text-danger ml-1"></i></span>
            </div>
            <div class="d-sm-flex justify-content-center justify-content-sm-between">
                <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Distributed by <a href="https://www.themewagon.com/" target="_blank">Themewagon</a></span>
            </div> */}


            <p className="d-sm-flex justify-content-center justify-content-sm-between">Made with ❤️ by<a target={"_blank"} href='https://nimishpatel.me' style={{ color: 'rgb(90, 250, 148)', marginLeft: 5 }}> Nimish Patel</a></p>


        </footer>
    )
}

export default Footer;