import { useEffect, useState } from 'react';
function Verify(props) {

    const [isLoading, setIsLoading] = useState(true);
    const [code, setCode] = useState(0);
    useEffect(() => {
        (async () => {
            var url = new URL(window.location.href);
            var uid = url.searchParams.get("id");
            var uvc = url.searchParams.get("vc");
            console.log(uid);
            console.log(uvc);
            if (uid != null && uid.trim() != null && uvc != null && uvc.trim() != null) {
                var data = JSON.stringify({
                    'uid': encodeURI(uid),
                    'uvc': encodeURI(uvc)
                });
                console.log("data", data);
                fetch(`${process.env.REACT_APP_API_URL}auth/verify`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: data
                }).then(res => res.json()).then(res2 => {
                    setCode(res2.code);
                    setIsLoading(false);
                })
            }

        })();
    }, []);

    function renderState() {
        if (code == 1) {
            return (
                <div id="done" >
                    <h2>✅ Thanks for your verification.</h2><h2>  <a target="_blank" class="btn" href="/login">➡️ Login app</a></h2>
                </div>
            )
        }
        else if (code == 2) {
            return (
                <div id="alreadydone">
                    <h2>✅ You are already verified.</h2><h2>  <a target="_blank" class="btn" href="/login">➡️ Login app</a></h2>
                </div>
            )
        }
        else if (code == -1) {
            return (
                <div id="wrong">
                    <h2 >😥 Oops! something went wrong.</h2><h2> But don't worry, <a target="_blank" class="" href="mailto:hello@trytwig.com">Click here ✉️</a> and share your issue.</h2>
                </div>
            )
        }
        else
            return null;
    }
    return (
        <div class="container-scroller">
            <div class="container-fluid page-body-wrapper full-page-wrapper">
                <div class="content-wrapper d-flex align-items-center auth px-0">
                    <div class="row w-100 mx-0">
                        <div class="col-lg-4 mx-auto">
                            <a href="/" title="Go to website."> <h1 class="font-weight-medium">InstaWrites</h1></a>
                            {isLoading ?
                                <h2 id="wait">⌛ Wait...</h2>
                                : null}
                            {renderState()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Verify;