import React, { useEffect } from 'react';
import { useState } from "react";
import { ThreeDots } from 'react-loader-spinner';
import swal from "sweetalert";
import { loginType } from '../../common/global';

function Register() {

    useEffect(() => {
        (async () => {
            fetch(`https://write-app-api.onrender.com`)
        })();
    }, []);
    const [signUp, setSignUp] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: ""
    });

    const [firstNameError, setFirstNameError] = useState('')
    const [lastNameError, setLastNameError] = useState('')
    const [emailError, setEmailError] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [isloading, setIsLoading] = useState(false)

    const handleInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setSignUp({ ...signUp, [name]: value })
    }
    function isValid() {
        let isValid = true
        if (signUp.firstName == "") {
            isValid = false
            setFirstNameError("Please fill this required field !")
        } else {
            setFirstNameError("")
        }
        if (signUp.lastName == "") {
            isValid = false
            setLastNameError("Please fill this required field !")
        } else {
            setLastNameError("")
        }
        if (signUp.email == "") {
            isValid = false
            setEmailError("Please fill this required field !")
        } else {
            setEmailError("")
        } if (signUp.password == "") {
            isValid = false
            setPasswordError("Please fill this required field !")
        } else {
            setPasswordError("")
        }
        return isValid
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (isValid()) {
            setIsLoading(true);
            let userdata = {
                firstName: signUp.firstName,
                lastName: signUp.lastName,
                email: signUp.email,
                password: signUp.password,
                loginType: loginType.email
            }
            fetch(`${process.env.REACT_APP_API_URL}auth/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userdata)
            }).then(res => res.json()).then(res2 => {
                setIsLoading(false);
                swal("Signup successful. Please verify your email.", `${res2.message}`, "success").then((value) => {
                    if (value)
                        window.location.replace('/login')
                });;

            })
        }
    }
    return (
        <div class="container-scroller">
            <div class="container-fluid page-body-wrapper full-page-wrapper">
                <div class="content-wrapper d-flex align-items-center auth px-0">
                    <div class="row w-100 mx-0">
                        <div class="col-lg-4 mx-auto">
                            <div class="auth-form-light text-center py-5 px-4 px-sm-5">
                                <div class="brand-logo">
                                    {/* <img src="../../images/logo.svg" alt="logo" /> */}
                                    <h1 class="font-weight-medium">InstaWrites</h1>
                                </div>
                                <h4>New here?</h4>
                                <h6 class="font-weight-light">Signing up is easy. It only takes a few steps</h6>
                                <form class="pt-3">
                                    <div class="form-group">
                                        <input type="text" class="form-control form-control-lg" id="exampleInputUsername1" name="firstName" placeholder="First name" onChange={handleInput} />
                                        {firstNameError ? <p className="text-danger text-xs italic">{firstNameError}</p> : null}
                                    </div>
                                    <div class="form-group">
                                        <input type="text" class="form-control form-control-lg" id="exampleInputUsername1" name="lastName" placeholder="Last name" onChange={handleInput} />
                                        {lastNameError ? <p className="text-danger text-xs italic">{lastNameError}</p> : null}
                                    </div>
                                    <div class="form-group">
                                        <input type="email" class="form-control form-control-lg" id="exampleInputEmail1" name="email" placeholder="Email" onChange={handleInput} />
                                        {emailError ? <p className="text-danger text-xs italic">{emailError}</p> : null}
                                    </div>
                                    <div class="form-group">
                                        <input type="password" class="form-control form-control-lg" id="exampleInputPassword1" name="password" placeholder="Password" onChange={handleInput} />
                                        {passwordError ? <p className="text-danger text-xs italic">{passwordError}</p> : null}
                                    </div>
                                    <div class="mb-4">
                                        <div class="form-check">
                                            <label class="form-check-label text-muted">
                                                <input type="checkbox" class="form-check-input" />
                                                I agree to all Terms & Conditions
                                            </label>
                                        </div>
                                    </div>
                                    <div class="mt-3">
                                        <button class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" onClick={handleSubmit} disabled={isloading}>SIGN UP</button>
                                    </div>
                                    <div class="text-center mt-4 font-weight-light">
                                        Already have an account? <a href="/login" class="text-primary">Login</a>
                                    </div>
                                    {isloading ?
                                        <div className='loading'>
                                            <ThreeDots type="ThreeDots" color="#2BAD60" height="70" width="70" />
                                        </div>
                                        : null}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Register;