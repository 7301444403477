import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import Sidebar from "../Sidebar";

function Articles() {
    let navigate = useNavigate();
    const [allPost, setAllPost] = useState([]);
    const userId = Cookies.get('userId');
    const siteUrl = Cookies.get('siteUrl');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        (async () => {
            if (userId != undefined) {
                console.log(`${process.env.REACT_APP_API_URL}allposts/${userId}`)
                let res = await fetch(`${process.env.REACT_APP_API_URL}allposts/${userId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });

                let result = await res.json();
                console.log("result", result);
                if (result.success) {
                    setAllPost(result.data);
                }
                setIsLoading(false);
            }
        })();
    }, []);
    return (
        <div class="container-fluid page-body-wrapper">
            <Sidebar active="articles" />
            <div class="main-panel">
                <div class="content-wrapper">
                    {/* <div class="col-lg-12 row">
                        <div class="col-lg-2 grid-margin stretch-card">
                            <div class="card">
                                <div class="card-body align-items-center justify-content-center text-center">
                                    <div>
                                        <i class="icon-plus"></i>
                                        <h4 class="card-title mt-2">Add Article</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div class="col-lg-12 grid-margin stretch-card">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="card-title">All Articles</h4>
                                {/* <p class="card-description">
                                    Add class <code>.table-hover</code>
                                </p> */}
                                <div class="table-responsive">
                                    <table class="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>Title</th>
                                                <th>Category</th>
                                                <th>Author</th>
                                                <th>PubDate</th>
                                                {/* <th>Status</th> */}
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {allPost.map((post, index) => (
                                                <tr>
                                                    <td>{post.title}</td>
                                                    <td>{post.categoryId?.name}</td>
                                                    <td>{post.authorId?.name}</td>
                                                    <td>{post.pubDate}</td>
                                                    {/* <td><label class="badge badge-danger">{post.isApproved ? "Approved" : "Pending"}</label></td> */}
                                                    <td>
                                                        <button class="btn btn-primary mr-2" onClick={() => { window.location = `/${post._id}`; }}><i class="ti-pencil"></i></button>
                                                        <button class="btn btn-primary mr-2" onClick={() => { window.open(siteUrl + "/post/" + post.slug, "_blank") }}><i class="ti-eye"></i></button>
                                                    </td>
                                                </tr>
                                            ))
                                            }
                                            {isLoading ?
                                                <div className='loading'>
                                                    <ThreeDots type="ThreeDots" color="#2BAD60" height="70" width="70" />
                                                </div>
                                                : null}
                                            {/* 
                                            <tr>
                                                <td>Jacob</td>
                                                <td>Photoshop</td>
                                                <td class="text-danger"> 28.76% <i class="ti-arrow-down"></i></td>
                                                <td><label class="badge badge-danger">Pending</label></td>
                                            </tr>
                                            <tr>
                                                <td>Messsy</td>
                                                <td>Flash</td>
                                                <td class="text-danger"> 21.06% <i class="ti-arrow-down"></i></td>
                                                <td><label class="badge badge-warning">In progress</label></td>
                                            </tr>
                                            <tr>
                                                <td>John</td>
                                                <td>Premier</td>
                                                <td class="text-danger"> 35.00% <i class="ti-arrow-down"></i></td>
                                                <td><label class="badge badge-info">Fixed</label></td>
                                            </tr>
                                            <tr>
                                                <td>Peter</td>
                                                <td>After effects</td>
                                                <td class="text-success"> 82.00% <i class="ti-arrow-up"></i></td>
                                                <td><label class="badge badge-success">Completed</label></td>
                                            </tr>
                                            <tr>
                                                <td>Dave</td>
                                                <td>53275535</td>
                                                <td class="text-success"> 98.05% <i class="ti-arrow-up"></i></td>
                                                <td><label class="badge badge-warning">In progress</label></td>
                                            </tr> */}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Articles;