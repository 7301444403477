import Cookies from 'js-cookie';
import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import trackPathForAnalytics from '../../common/TrackPathForAnalytics';
import logoMini from '../../images/logo.png';
// import profile from '../../images/faces/face28.jpg';
function Header() {

    //Analytics
    const { pathname, search } = useLocation();
    const analytics = useCallback(() => {
        let pname = pathname.split("/")[1];
        if (pname.match(/^[0-9a-fA-F]{24}$/)) {
            console.log("mongoose Id")
            trackPathForAnalytics({ path: "/article", search: search, title: "article" });
        }
        else {
            pname = pname == "" ? "home" : pname;
            trackPathForAnalytics({ path: pathname, search: search, title: pname });
        }
    }, [pathname, search]);

    useEffect(() => {
        analytics();
    }, [analytics]);

    const onLogout = () => {
        Cookies.remove("siteStatus");
        Cookies.remove("siteId");
        Cookies.remove("siteUrl");
        Cookies.remove("InstaWritesToken");
        Cookies.remove("userId");
        window.location = "/login";
    }
    return (
        <nav class="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
            <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
                <a class="navbar-brand brand-logo mr-5" href="/"><div><img src={logoMini} alt="logo" /> InstaWrites</div></a>
                <a class="navbar-brand brand-logo-mini" href="/"><img src={logoMini} alt="logo" /></a>
            </div>
            <div class="navbar-menu-wrapper d-flex align-items-center justify-content-end">
                <button class="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
                    <span class="icon-menu"></span>
                </button>
                <ul class="navbar-nav mr-lg-6">
                    <li class="nav-item nav-search d-none d-lg-block">
                        <div class="input-group">
                            <div class="input-group-prepend hover-cursor" id="navbar-search-icon">
                                <span class="input-group-text" id="search">
                                    <i class="icon-search"></i>
                                </span>
                            </div>
                            <input type="text" class="form-control" id="navbar-search-input" placeholder="Search now" aria-label="search" aria-describedby="search" />
                        </div>
                    </li>
                </ul>
                <ul class="navbar-nav navbar-nav-right">
                    <li class="nav-item dropdown">
                        <a class="nav-link count-indicator dropdown-toggle" id="notificationDropdown" href="#" data-toggle="dropdown">
                            <i class="icon-bell mx-0"></i>
                            <span class="count"></span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="notificationDropdown">
                            <p class="mb-0 font-weight-normal float-left dropdown-header">Notifications</p>
                            <a class="dropdown-item preview-item">
                                <div class="preview-thumbnail">
                                    <div class="preview-icon bg-success">
                                        <i class="ti-info-alt mx-0"></i>
                                    </div>
                                </div>
                                <div class="preview-item-content">
                                    <h6 class="preview-subject font-weight-normal">Application Error</h6>
                                    <p class="font-weight-light small-text mb-0 text-muted">
                                        Just now
                                    </p>
                                </div>
                            </a>
                            <a class="dropdown-item preview-item">
                                <div class="preview-thumbnail">
                                    <div class="preview-icon bg-warning">
                                        <i class="ti-settings mx-0"></i>
                                    </div>
                                </div>
                                <div class="preview-item-content">
                                    <h6 class="preview-subject font-weight-normal">Settings</h6>
                                    <p class="font-weight-light small-text mb-0 text-muted">
                                        Private message
                                    </p>
                                </div>
                            </a>
                            <a class="dropdown-item preview-item">
                                <div class="preview-thumbnail">
                                    <div class="preview-icon bg-info">
                                        <i class="ti-user mx-0"></i>
                                    </div>
                                </div>
                                <div class="preview-item-content">
                                    <h6 class="preview-subject font-weight-normal">New user registration</h6>
                                    <p class="font-weight-light small-text mb-0 text-muted">
                                        2 days ago
                                    </p>
                                </div>
                            </a>
                        </div>
                    </li>
                    <li class="nav-item nav-profile dropdown">
                        <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" id="profileDropdown">
                            {/* <img src={profile} alt="profile" /> */}
                            <div className='nameprofile'><i className='ti-user'></i></div>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right navbar-dropdown profileDropdown" data-toggle="dropdown" aria-labelledby="profileDropdown">
                            {/* <a class="dropdown-item">
                                <i class="ti-settings text-primary"></i>
                                Settings
                            </a> */}
                            <a class="dropdown-item" onClick={onLogout}>
                                <i class="ti-power-off text-primary"></i>
                                Logout
                            </a>
                        </div>
                    </li>
                    {/* <li class="nav-item nav-settings d-none d-lg-flex">
                        <a class="nav-link" href="#">
                            <i class="icon-ellipsis"></i>
                        </a>
                    </li> */}
                </ul>
                <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
                    <span class="icon-menu"></span>
                </button>
            </div>
        </nav>
    );
}

export default Header;