import React, { useEffect } from 'react';
import { useState } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
function Login() {
    let navigate = useNavigate();
    const [emailError, setEmailError] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [login, setlogin] = useState({
        email: "",
        password: ""
    });
    const [isloading, setIsLoading] = useState(false)

    useEffect(() => {
        (async () => {
            fetch(`https://write-app-api.onrender.com`)
        })();
    }, []);

    const handleInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setlogin({ ...login, [name]: value })
    }

    function isValid() {
        let isValid = true
        if (login.email == "") {
            isValid = false
            setEmailError("Please fill this required field !")
        } else {
            setEmailError("")
        }
        if (login.password == "") {
            isValid = false
            setPasswordError("Please fill this required field !")
        } else {
            setPasswordError("")
        }
        return isValid
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (isValid()) {
            let userdata = {
                email: login.email,
                password: login.password
            }
            setIsLoading(true);
            fetch(`${process.env.REACT_APP_API_URL}auth/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userdata)
            })
                .then(res => res.json())
                .then(res2 => {
                    if (res2.success) {
                        document.cookie = `InstaWritesToken=${res2.token};max-age=` + 60 * 60 * 5;
                        document.cookie = `userId=${res2.data.user._id};max-age=` + 60 * 60 * 5;
                        if (res2.data.site != undefined && res2.data.site != null) {
                            let siteUrl = "";
                            if (res2.data.site.customDomain != undefined && res2.data.site.customDomain != "")
                                siteUrl = "https://" + res2.data.site.customDomain;
                            else
                                siteUrl = res2.data.site.siteUrl;
                            //Set cookies
                            document.cookie = `siteId=${res2.data.site._id};max-age=` + 60 * 60 * 5;
                            document.cookie = `siteUrl=${siteUrl};max-age=` + 60 * 60 * 5;
                            window.location.replace('/')
                        }
                        else {
                            console.log("goes for site creattion");
                            // createNewSiteForUser(res2.data.user._id);
                            navigate("/onboard");
                        }

                    } else {
                        console.log(res2)
                        swal(`${res2.message}`, `${login.email}`, "warning");
                    }
                    setIsLoading(false);
                })
        }
    }
    //Extra functions
    // const createNewSiteForUser = (userId) => {
    //     fetch(`${process.env.REACT_APP_API_URL}sites/create`, {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify({ "userId": userId })
    //     })
    //         .then(res => res.json())
    //         .then(res2 => {
    //             if (res2.success) {
    //                 console.log("site created", res2);
    //                 document.cookie = `siteId=${res2.data._id};max-age=` + 60 * 60 * 5;
    //                 document.cookie = `siteUrl=${res2.data.siteUrl};max-age=` + 60 * 60 * 5;
    //             }
    //             window.location.replace('/')
    //         })
    // }

    return (
        <div class="container-scroller">
            <div class="container-fluid page-body-wrapper full-page-wrapper">
                <div class="content-wrapper d-flex align-items-center auth px-0">
                    <div class="row w-100 mx-0">
                        <div class="col-lg-4 mx-auto">
                            <div class="auth-form-light text-center py-5 px-4 px-sm-5">
                                <div class="brand-logo">
                                    {/* <img src="../../images/logo.svg" alt="logo" /> */}
                                    <h1 class="font-weight-medium">InstaWrites</h1>
                                </div>
                                <h3>Hello! let's get started</h3>
                                <h6 class="font-weight-light">Sign in to continue.</h6>
                                <form class="pt-3">
                                    <div class="form-group">
                                        <input type="email" class="form-control form-control-lg" id="exampleInputEmail1" name="email" placeholder="Email" onChange={handleInput} />
                                        {emailError ? <p className="text-danger text-xs italic">{emailError}</p> : null}
                                    </div>
                                    <div class="form-group">
                                        <input type="password" class="form-control form-control-lg" id="exampleInputPassword1" name="password" placeholder="Password" onChange={handleInput} />
                                        {emailError ? <p className="text-danger text-xs italic">{emailError}</p> : null}
                                    </div>
                                    <div class="mt-3">
                                        <button class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" onClick={handleSubmit} disabled={isloading}>SIGN IN</button>
                                    </div>
                                    <div class="my-2 d-flex justify-content-end align-items-center">
                                        {/* <div class="form-check">
                                            <label class="form-check-label text-muted">
                                                <input type="checkbox" class="form-check-input" />
                                                Keep me signed in
                                            </label>
                                        </div> */}
                                        <a href="#" class="auth-link text-black">Forgot password?</a>
                                    </div>
                                    {/* <div class="mb-2">
                                        <button type="button" class="btn btn-block btn-google auth-form-btn">
                                            <i class="ti-google mr-2"></i>Sign in with Google
                                        </button>
                                    </div> */}
                                    <div class="text-center mt-4 font-weight-light">
                                        Don't have an account? <a href="/signup" class="text-primary">Create</a>
                                    </div>
                                    {isloading ?
                                        <div className='loading'>
                                            <ThreeDots type="ThreeDots" color="#2BAD60" height="70" width="70" />
                                        </div>
                                        : null}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Login;