import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Cookies from 'js-cookie';
//css imports
import "./css/feather/feather.css";
import "./css/ti-icons/css/themify-icons.css";
import "./css/vendor.bundle.base.css"
import "./css/datatable/dataTables.bootstrap4.css";
import "./css/ti-icons/css/themify-icons.css";
import "./css/datatable/select.dataTables.min.css";
import "./css/vertical-layout-light/style.css";
import "./css/style.css";


import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Home from "./components/Home";
import Articles from "./components/Articles";
import Authors from "./components/Authors";
import Team from "./components/Team";
import Domain from "./components/Domain";
import Templates from "./components/Templates";
import Settings from "./components/Settings";
import Login from "./components/User/Login";
import Register from "./components/User/Register";
import Verify from "./components/User/Verify";

import { Helmet } from "react-helmet";
import Categories from "./components/Categories";
import Onboard from "./components/User/Onboard";

function App() {

  const token = Cookies.get('InstaWritesToken');
  if (!token) {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Register />} />
          <Route path="/verify" element={<Verify />} />
          <Route path="/onboard" element={<Onboard />} />
        </Routes>
        <Footer />
      </Router>
    )
  }
  else {
    return (
      <Router>
        <Header></Header>
        <Routes>
          <Route path="/" element={<Home />} >
            <Route path=":id" element={<Home />} />
          </Route>
          <Route path="/articles" element={<Articles />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/authors" element={<Authors />} />
          <Route path="/teammembers" element={<Team />} />
          <Route path="/domain" element={<Domain />} />
          <Route path="/templates" element={<Templates />} />
          <Route path="/settings" element={<Settings />} />
        </Routes>
        <Helmet>
          <script src="js/navbar.js" type="text/javascript" />
        </Helmet>
        <Footer />
      </Router>
    );
  }
}

export default App;