import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import swal from "sweetalert";
import Footer from "../Footer/Footer";
import Sidebar from "../Sidebar";

const templateArray = [
    { id: "538490919", name: "Royal", description: "", image: 'https://ik.imagekit.io/fcd5oxgok/Royal.png' },
    { id: "583342674", name: "Stablo", description: "", image: 'https://ik.imagekit.io/fcd5oxgok/Stablo.png' },
]
function Templates() {
    const userId = Cookies.get('userId');
    const siteId = Cookies.get('siteId');
    const [isLoading, setIsLoading] = useState(true);
    const [selectedTemplate, setSelectedTemplate] = useState([]);
    useEffect(() => {
        (async () => {
            if (userId != undefined && siteId != undefined) {
                console.log(`${process.env.REACT_APP_API_URL}template/${userId}/${siteId}`)
                let res = await fetch(`${process.env.REACT_APP_API_URL}template/${userId}/${siteId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });

                let result = await res.json();
                console.log("result", result);
                if (result.success) {
                    setSelectedTemplate(result.data?.templateId);
                }
                setIsLoading(false);
            }
        })();
    }, []);
    const onSave = () => {
        setIsLoading(true);
        let template = {};
        template.userId = userId;
        template.siteId = siteId;
        template.templateId = selectedTemplate;
        // category.isActive = isActive;
        fetch(`${process.env.REACT_APP_API_URL}template/save`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(template)
        }).then(res => res.json()).then(result => {
            console.log(result);
            if (result.success) {
                swal("Submitted", `Template saved successfully.`, "success");
            }
            setIsLoading(false);
        })
    }
    return (
        <div class="container-fluid page-body-wrapper">
            <Sidebar active="templates" />
            <div class="main-panel">
                <div class="content-wrapper">
                    <div class="row ">
                        <div className="col-lg-3 grid-margin">Select Your website template:</div>
                    </div>
                    <div class="row">
                        {isLoading ?
                            <div className='loading'>
                                <ThreeDots type="ThreeDots" color="#2BAD60" height="70" width="70" />
                            </div>
                            : null}
                        {templateArray.map((temp, index) => (
                            <div key={index} class="col-lg-3 grid-margin stretch-card" onClick={() => setSelectedTemplate(temp.id)}>
                                <div class={"card " + (selectedTemplate == temp.id ? "border border-dark" : "")}>
                                    <div class="card-body" style={{ textAlign: 'center' }}>
                                        <span style={{ fontWeight: 'bold', alignSelf: 'flex-end' }}>{temp.name}</span>
                                        <img src={temp.image} style={{ width: '100%' }} />

                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div class="row ml-3">
                        <button type="button" class="btn btn-primary mr-2" onClick={onSave}>Save</button>
                    </div>
                    <div style={{ position: 'absolute', bottom: 20, }}>
                        Note: Changing template will take 5-10 minutes to reflect changes on your site.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Templates;