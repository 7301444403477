import Cookies from "js-cookie";
import { useState } from "react";
import swal from "sweetalert";
import Footer from "../Footer/Footer";
import Sidebar from "../Sidebar";
import sampledomainImg from '../../images/sampledomain.png';
function Domain() {
    const [textDomain, setTextDomain] = useState("");

    const siteId = Cookies.get('siteId');
    const [siteUrl, setSiteUrl] = useState(Cookies.get('siteUrl'));
    const handleChange = (event) => {
        setTextDomain(event.target.value);
    };
    const savedomain = async () => {
        console.log("siteId", siteId);
        fetch(`${process.env.REACT_APP_API_URL}sites/setdomain`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                siteId: siteId,
                domain: textDomain
            })
        })
            .then(res => res.json())
            .then(res2 => {
                console.log("res2", res2);
                if (res2.success) {
                    if (res2.data.customDomain != undefined) {
                        let siteUrl = "https://" + res2.data.customDomain;
                        document.cookie = `siteUrl=${siteUrl};max-age=` + 60 * 60 * 5;
                    }
                    swal("Message", `Domain updated successfully. It can take upto 1 min to 24 hours to update dns.`, "success")
                }
                else {
                    swal("Message", `Please contact support.`, "warning")
                }
            });
    }

    const removeDomain = async () => {
        console.log("siteId", siteId);
        if (window.confirm("Are you sure want to remove domain?")) {
            fetch(`${process.env.REACT_APP_API_URL}sites/removedomain`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    siteId: siteId,
                })
            })
                .then(res => res.json())
                .then(res2 => {
                    console.log("res2", res2);
                    if (res2.success) {
                        if (res2.data.siteUrl != undefined) {
                            let siteUrl = "https://" + res2.data.siteUrl;
                            document.cookie = `siteUrl=${siteUrl};max-age=` + 60 * 60 * 5;
                            setSiteUrl(res2.data.siteUrl);
                        }
                        swal("Message", `Domain removed successfully. It can take upto 1 min to 24 hours to update dns.`, "success")
                    }
                    else {
                        swal("Message", `Please contact support.`, "warning")
                    }
                });
        }
    }
    return (
        <div class="container-fluid page-body-wrapper">
            <Sidebar active="domain" />

            <div class="main-panel">
                <div class="content-wrapper">
                    <div class="col-lg-12 grid-margin stretch-card">
                        <div class="card">
                            <div class="card-body">
                                <div style={{ padding: 20 }}>Setup your domain here</div>
                                {siteUrl.indexOf("vercel") !== -1 ?
                                    <ol>
                                        <li>
                                            Set <b>"cname"</b> to your subdomain:
                                            {/* <b>{siteUrl.replace("https://", "")}</b> */}
                                        </li>
                                        <img src={sampledomainImg} style={{ height: 200 }} />
                                        <br />
                                        <li>
                                            <input type="text" class="form-control form-control-lg" placeholder="Enter subdomain name" onChange={handleChange} value={textDomain} />
                                        </li>
                                        <br />
                                        <li>
                                            <button className="btnSetDomain" onClick={savedomain}>Set Domain</button>
                                        </li>
                                    </ol>
                                    :
                                    <div class="form-control">
                                        Domain set to: <a href={siteUrl} target="_blank">{siteUrl}</a>

                                        <button className="btn-primary" onClick={removeDomain}>Remove Domain</button>

                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Domain;