import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import "../../css/modal/index.css";
import { convertToSlug } from "../../common/global";
import { ThreeDots } from "react-loader-spinner";
function Categories() {

    const [allCategory, setAllCategory] = useState([]);
    const userId = Cookies.get('userId');
    const siteId = Cookies.get('siteId');
    const [isLoading, setIsLoading] = useState(true);

    const [category, setCategory] = useState({});
    // const [isActive, setIsActive] = useState(false);
    useEffect(() => {
        (async () => {
            getData();
        })();
    }, []);

    const getData = async () => {
        if (userId != undefined) {
            let res = await fetch(`${process.env.REACT_APP_API_URL}categorybyuser/${userId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            let result = await res.json();
            console.log("result", result);
            if (result.success) {
                setAllCategory(result.data == null ? [] : result.data);
            }
            setIsLoading(false);
        }
    }
    const onSave = () => {
        category.userId = userId;
        category.siteId = siteId;
        // category.isActive = isActive;
        fetch(`${process.env.REACT_APP_API_URL}categories`, {
            method: category._id ? 'PUT' : 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(category)
        }).then(res => res.json()).then(result => {
            console.log(result);
            if (result.success) {
                closeModal();
                getData();
            }
        })
    }
    const openModal = () => {
        var modal = document.getElementById("myModal");
        modal.style.display = "block";
    }
    const closeModal = () => {
        var modal = document.getElementById("myModal");
        modal.style.display = "none";
    }
    const handleChange = e => {
        const { name, value } = e.target;
        setCategory(prevState => ({
            ...prevState,
            [name]: value
        }));
        if (name == "name") {
            setCategory(prevState => ({
                ...prevState,
                slug: convertToSlug(value)
            }));
        }
    };
    return (
        <div class="container-fluid page-body-wrapper">
            <Sidebar active="categories" />
            <div class="main-panel">
                <div class="content-wrapper">
                    <div class="col-lg-12 row">
                        <div class="col-lg-2 grid-margin stretch-card">
                            <div class="card">
                                <div class="card-body align-items-center justify-content-center text-center" onClick={openModal}>
                                    <div>
                                        <i class="icon-plus"></i>
                                        <h4 class="card-title mt-2">Add Category</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 grid-margin stretch-card">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="card-title">All Categories</h4>
                                {/* <p class="card-description">
                                    Add class <code>.table-hover</code>
                                </p> */}
                                <div class="table-responsive">
                                    <table class="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Slug</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                allCategory.map((category, index) => (
                                                    <tr>
                                                        <td>{category.name}</td>
                                                        <td>{category.slug}</td>
                                                        <td><label class="badge">{category.isActive ? "Active" : "In Active"}</label></td>
                                                        <td>
                                                            <button class="btn btn-primary mr-2" onClick={() => { setCategory(category); openModal() }}>Edit</button>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                            {isLoading ?
                                                <div className='loading'>
                                                    <ThreeDots type="ThreeDots" color="#2BAD60" height="70" width="70" />
                                                </div>
                                                : null}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="myModal" class="modal">
                <div class="modal-content d-flex">
                    <span class="close" onClick={closeModal}>&times;</span>
                    <div class="row">
                        <div class="col-md-12 grid-margin stretch-card">
                            <div class="card">
                                <div class="card-body">
                                    <h4 class="card-title">Category</h4>
                                    <form class="forms-sample">
                                        <div class="form-group">
                                            <label for="exampleInputUsername1">Name</label>
                                            <input type="text" class="form-control" placeholder="Enter name" value={category?.name} name="name" onChange={handleChange} />
                                        </div>
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Slug</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1" placeholder="Category Slug" disabled value={category?.slug} name="slug" />
                                        </div>

                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Description</label>
                                            <textarea class="form-control" placeholder="Description" value={category?.desciption} name="desciption" onChange={handleChange} />
                                        </div>
                                        <div class="form-check">
                                            <label class="">
                                                <input type="checkbox" value="" id="flexCheckChecked" checked={category?.isActive} onChange={(event) => {
                                                    setCategory(prevState => ({
                                                        ...prevState,
                                                        isActive: !category.isActive
                                                    }));
                                                }} />
                                                Active
                                            </label>
                                        </div>
                                        <button type="button" class="btn btn-primary mr-2" onClick={onSave}>Submit</button>
                                        <button type="button" class="btn btn-light" onClick={closeModal}>Cancel</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Categories;