import Cookies from 'js-cookie';
import React, { useEffect } from 'react';
import { useState } from "react";
import { ThreeDots } from 'react-loader-spinner';

function Onboard() {
    const userId = Cookies.get('userId');

    const [isLoading, setIsLoading] = useState(true);
    const [siteStatusText, setSiteStatusText] = useState('Creating....')

    useEffect(() => {
        (async () => {
            if (userId) {
                createNewSiteForUser(userId);
            }
        })();
    }, []);

    const createNewSiteForUser = (userId) => {
        fetch(`${process.env.REACT_APP_API_URL}sites/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ "userId": userId })
        })
            .then(res => res.json())
            .then(res2 => {
                if (res2.success) {
                    console.log("site created", res2);
                    document.cookie = `siteId=${res2.data._id};max-age=` + 60 * 60 * 5;
                    document.cookie = `siteUrl=${res2.data.siteUrl};max-age=` + 60 * 60 * 5;
                    setSiteStatusText(res2.data.siteUrl);
                }
                setIsLoading(false);
            })
    }

    const onStart = () => {
        window.location.replace('/');
    }


    return (
        <div class="container-scroller">
            <div class="container-fluid page-body-wrapper full-page-wrapper">
                <div class="content-wrapper d-flex align-items-center auth px-0">
                    <div class="row w-100 mx-0">
                        <div class="col-lg-4 mx-auto">
                            <div class="auth-form-light text-center py-5 px-4 px-sm-5">
                                <div class="brand-logo">
                                    <h1 class="font-weight-medium">InstaWrites</h1>
                                </div>
                                <h3>Creating your default site:</h3>
                                <div class="form-group mt-4">
                                    <input type="text" class="form-control form-control-lg" name="siteaddress" placeholder={siteStatusText} disabled={true} />
                                </div>
                                <div class="mt-3">
                                    <button class="btn btn-block btn-success btn-lg font-weight-medium auth-form-btn" onClick={onStart} disabled={isLoading}>Let's START</button>
                                </div>
                                {isLoading ?
                                    <div className='loading'>
                                        <ThreeDots type="ThreeDots" color="#2BAD60" height="70" width="70" />
                                    </div>
                                    : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Onboard;