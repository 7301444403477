function Sidebar(props) {
    return (
        <nav class="sidebar sidebar-offcanvas" id="sidebar">
            <ul class="nav">
                <li class={props.active == "home" ? "nav-item active" : "nav-item"}>
                    <a class="nav-link" href="/">
                        <i class="ti-write menu-icon"></i>
                        <span class="menu-title">Write</span>
                    </a>
                </li>
                <li class={props.active == "articles" ? "nav-item active" : "nav-item"}>
                    <a class="nav-link" data-toggle="collapse" href="/articles" aria-expanded="false" aria-controls="ui-basic">
                        <i class="ti-files menu-icon"></i>
                        <span class="menu-title">Articles</span>
                        {/* <i class="menu-arrow"></i> */}
                    </a>
                    {/* <div class="collapse" id="ui-basic">
                        <ul class="nav flex-column sub-menu">
                            <li class="nav-item"> <a class="nav-link" href="pages/ui-features/buttons.html">Buttons</a></li>
                            <li class="nav-item"> <a class="nav-link" href="pages/ui-features/dropdowns.html">Dropdowns</a></li>
                            <li class="nav-item"> <a class="nav-link" href="pages/ui-features/typography.html">Typography</a></li>
                        </ul>
                    </div> */}
                </li>
                <li class={props.active == "categories" ? "categories nav-item active" : "categories nav-item"}>
                    <a class="nav-link" data-toggle="collapse" href="/categories" aria-expanded="false" aria-controls="form-elements">
                        <i class="ti-layout-grid2 menu-icon"></i>
                        <span class="menu-title">Categories</span>
                        {/* <i class="menu-arrow"></i> */}
                    </a>
                    {/* <div class="collapse" id="form-elements">
                        <ul class="nav flex-column sub-menu">
                            <li class="nav-item"><a class="nav-link" href="pages/forms/basic_elements.html">Basic Elements</a></li>
                        </ul>
                    </div> */}
                </li>
                <li class={props.active == "authors" ? "authors nav-item active" : "authors nav-item"}>
                    <a class="nav-link" data-toggle="collapse" href="/authors" aria-expanded="false" aria-controls="form-elements">
                        <i class="ti-ink-pen menu-icon"></i>
                        <span class="menu-title">Authors</span>
                        {/* <i class="menu-arrow"></i> */}
                    </a>
                    {/* <div class="collapse" id="form-elements">
                        <ul class="nav flex-column sub-menu">
                            <li class="nav-item"><a class="nav-link" href="pages/forms/basic_elements.html">Basic Elements</a></li>
                        </ul>
                    </div> */}
                </li>
                <li class={props.active == "team" ? "nav-item active" : "nav-item"}>
                    <a class="nav-link" data-toggle="collapse" href="/teammembers" aria-expanded="false" aria-controls="charts">
                        <i class="ti-user menu-icon"></i>
                        <span class="menu-title">Team Members</span>
                        {/* <i class="menu-arrow"></i> */}
                    </a>
                    {/* <div class="collapse" id="charts">
                        <ul class="nav flex-column sub-menu">
                            <li class="nav-item"> <a class="nav-link" href="pages/charts/chartjs.html">ChartJs</a></li>
                        </ul>
                    </div> */}
                </li>
                <li class={props.active == "domain" ? "domain nav-item active" : "domain nav-item"}>
                    <a class="nav-link" data-toggle="collapse" href="/domain" aria-expanded="false" aria-controls="tables">
                        <i class="icon-globe menu-icon"></i>
                        <span class="menu-title">Domain</span>
                        {/* <i class="menu-arrow"></i> */}
                    </a>
                    {/* <div class="collapse" id="tables">
                        <ul class="nav flex-column sub-menu">
                            <li class="nav-item"> <a class="nav-link" href="pages/tables/basic-table.html">Basic table</a></li>
                        </ul>
                    </div> */}
                </li>
                <li class={props.active == "templates" ? "nav-item active" : "nav-item"}>
                    <a class="nav-link" data-toggle="collapse" href="/templates" aria-expanded="false" aria-controls="icons">
                        <i class="icon-contract menu-icon"></i>
                        <span class="menu-title">Templates</span>
                        {/* <i class="menu-arrow"></i> */}
                    </a>
                    {/* <div class="collapse" id="icons">
                        <ul class="nav flex-column sub-menu">
                            <li class="nav-item"> <a class="nav-link" href="pages/icons/mdi.html">Mdi icons</a></li>
                        </ul>
                    </div> */}
                </li>
                <li class={props.active == "settings" ? "nav-item active" : "nav-item"}>
                    <a class="nav-link" data-toggle="collapse" href="/settings" aria-expanded="false" aria-controls="auth">
                        <i class="ti-settings menu-icon"></i>
                        <span class="menu-title">Settings</span>
                        {/* <i class="menu-arrow"></i> */}
                    </a>
                    {/* <div class="collapse" id="auth">
                        <ul class="nav flex-column sub-menu">
                            <li class="nav-item"> <a class="nav-link" href="pages/samples/login.html"> Login </a></li>
                            <li class="nav-item"> <a class="nav-link" href="pages/samples/register.html"> Register </a></li>
                        </ul>
                    </div> */}
                </li>
                {/* <li class="nav-item">
                    <a class="nav-link" data-toggle="collapse" href="#error" aria-expanded="false" aria-controls="error">
                        <i class="icon-ban menu-icon"></i>
                        <span class="menu-title">Error pages</span>
                        <i class="menu-arrow"></i>
                    </a>
                    <div class="collapse" id="error">
                        <ul class="nav flex-column sub-menu">
                            <li class="nav-item"> <a class="nav-link" href="pages/samples/error-404.html"> 404 </a></li>
                            <li class="nav-item"> <a class="nav-link" href="pages/samples/error-500.html"> 500 </a></li>
                        </ul>
                    </div>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="pages/documentation/documentation.html">
                        <i class="icon-paper menu-icon"></i>
                        <span class="menu-title">Documentation</span>
                    </a>
                </li> */}
            </ul>
        </nav>
    )
}
export default Sidebar;