import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { TourProvider } from '@reactour/tour'
const root = ReactDOM.createRoot(document.getElementById('root'));

const steps = [
  {
    selector: '.toastui-editor-defaultUI',
    content: 'Start writing your blog here.',
  },
  {
    selector: '.setting-button',
    content: 'Click here to publish your article.',
  },
  {
    selector: '.site-status',
    content: 'Site status. Once it is getting ready, Access your site by clicking here. If you do not find your article on the website, just wait for a few seconds.'
  },
  {
    selector: '.categories',
    content: 'Create and manage your article categories.',
  },
  {
    selector: '.authors',
    content: 'Create and manage your article authors.',
  },
  {
    selector: '.domain',
    content: 'Set custom domain here. That\'s it. You are ready to go',
  }
];

const onCloseTour = (target) => {
  document.cookie = `siteTutorialCookie=true;max-age=` + (new Date()).getTime() + (10 * 365 * 24 * 60 * 60);
}
root.render(
  <React.StrictMode>
    <TourProvider steps={steps} beforeClose={onCloseTour}>
      <App />
    </TourProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
