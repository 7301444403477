import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import Footer from "../Footer/Footer";
import Sidebar from "../Sidebar";

function Settings() {

    const userId = Cookies.get('userId');
    const siteId = Cookies.get('siteId');
    const siteUrl = Cookies.get('siteUrl');
    const [isLoading, setIsLoading] = useState(true);
    const [setting, setSetting] = useState({});

    useEffect(() => {
        (async () => {
            getData();
        })();
    }, []);

    const getData = async () => {
        if (siteId != undefined) {
            setIsLoading(true);
            console.log(`${process.env.REACT_APP_API_URL}sitesetting/${siteId}`);
            let res = await fetch(`${process.env.REACT_APP_API_URL}sitesetting/${siteId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            let result = await res.json();
            console.log("result", result);
            if (result.success && result.data) {
                setSetting(result.data);
            }
            setIsLoading(false);
        }
    }
    const revalidate = () => {
        fetch(`${siteUrl}/api/revalidateall`);
    }
    const fileToBase64 = async (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = (e) => reject(e)
        })
    const onSave = () => {
        console.log("setting", setting);
        setting.siteId = siteId;
        if (!setting._id) {
            setting.createdById = userId;
        }
        else {
            setting.updatedById = userId;
            // setting.updatedDate = Date.now;
        }
        // category.isActive = isActive;
        fetch(`${process.env.REACT_APP_API_URL}sitesetting`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(setting)
        }).then(res => res.json()).then(result => {
            console.log(result);
            if (result.success) {
                swal("Submitted", `Settings saved successfully.`, "success");
                setSetting(result.data);
                revalidate();
            }
        })
    }

    const handleChange = async e => {
        const { name, value } = e.target;
        console.log(e);
        if (name == "logo" || name == "logoDark") {
            const file = e.target.files[0];
            const imageStr = await fileToBase64(file)
            console.log("imageStr", imageStr);
            setSetting(prevState => ({
                ...prevState,
                [name]: imageStr
            }));
        }
        else
            setSetting(prevState => ({
                ...prevState,
                [name]: value
            }));
    };

    return (
        <div class="container-fluid page-body-wrapper">
            <Sidebar active="settings" />
            <div class="main-panel">
                <div class="content-wrapper">
                    <div class="row">
                        <div class="col-md-8 grid-margin stretch-card">
                            <div class="card">
                                <div class="card-body">
                                    <h4 class="card-title">Settings</h4>
                                    {/* <p class="card-description">
                                        Basic form layout
                                    </p> */}
                                    <form class="forms-sample">
                                        <label for="exampleInputEmail1" className="card-title">Main Site Settings: </label>
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Site name</label>
                                            <input type="text" class="form-control" placeholder="Site name" name="name" value={setting.name} onChange={handleChange} />
                                        </div>
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Site description</label>
                                            <textarea type="text" class="form-control" placeholder="Site description" name="desciption" value={setting.desciption} onChange={handleChange} />
                                        </div>
                                        <div class="form-group">
                                            <label for="exampleInputPassword1">Site logo - Light</label>
                                            <input type="file" class="form-control" name="logo" onChange={handleChange} />
                                            <br />
                                            <img src={setting.logo} style={{ width: 200 }} />
                                        </div>
                                        <div class="form-group" >
                                            <label for="exampleInputPassword1">Site logo - Dark</label>
                                            <input type="file" class="form-control" name="logoDark" onChange={handleChange} />
                                            <br />
                                            <img src={setting.logoDark} style={{ width: 200, backgroundColor: 'black' }} />
                                        </div>
                                        <label for="exampleInputEmail1">Do you want to take approval from owner when writer write an article?</label>
                                        <div class="form-check form-check-flat form-check-primary">
                                            <label class="form-check-label">
                                                <input type="checkbox" class="form-check-input" name="isApprovalWorkflow" checked={setting.isApprovalWorkflow} onChange={(event) => {
                                                    setSetting(prevState => ({
                                                        ...prevState,
                                                        isApprovalWorkflow: !setting.isApprovalWorkflow
                                                    }));
                                                }} />
                                                Enable Approval
                                            </label>
                                        </div>
                                        <br />
                                        <label for="exampleInputEmail1" className="card-title">Address (Shows in Footer): </label>
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Address line-1</label>
                                            <input type="text" class="form-control" placeholder="Address line-1" name="addressline1" value={setting.addressline1} onChange={handleChange} />
                                        </div>
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Address line-1</label>
                                            <input type="text" class="form-control" placeholder="Address line-2" name="addressline2" value={setting.addressline2} onChange={handleChange} />
                                        </div>
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">City, Country</label>
                                            <input type="text" class="form-control" placeholder="City, Country" name="citycountry" value={setting.citycountry} onChange={handleChange} />
                                        </div>
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Contact email address</label>
                                            <input type="text" class="form-control" placeholder="Contact email address" name="conEmailAddress" value={setting.conEmailAddress} onChange={handleChange} />
                                        </div>
                                        <br />
                                        <label for="exampleInputEmail1" className="card-title">Social Media Links: </label>
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Facebook</label>
                                            <input type="text" class="form-control" placeholder="Enter Facebook link" name="linkFB" value={setting.linkFB} onChange={handleChange} />
                                        </div>
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Twitter</label>
                                            <input type="text" class="form-control" placeholder="Enter Twitter link" name="linkTwitter" value={setting.linkTwitter} onChange={handleChange} />
                                        </div>
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Instagram</label>
                                            <input type="text" class="form-control" placeholder="Enter Instagram link" name="linkInsta" value={setting.linkInsta} onChange={handleChange} />
                                        </div>
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Pinterest</label>
                                            <input type="text" class="form-control" placeholder="Enter Pinterest link" name="linkPin" value={setting.linkPin} onChange={handleChange} />
                                        </div>
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Linkedin</label>
                                            <input type="text" class="form-control" placeholder="Enter Linkedin link" name="linkLink" value={setting.linkLink} onChange={handleChange} />
                                        </div>
                                        <button type="button" class="btn btn-primary mr-2" onClick={onSave}>Submit</button>
                                        <button class="btn btn-light" type="button" onClick={() => window.location = "/"}>Cancel</button>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div >
        </div >
    )
}

export default Settings;