import { useEffect, useState, useRef } from 'react';
import Moment from 'react-moment';
import { Configuration, OpenAIApi } from "openai";
// import MdEditor from 'react-markdown-editor-lite';
// import MarkdownIt from 'markdown-it';
import $ from 'jquery';
import { useTour } from '@reactour/tour';
import 'react-markdown-editor-lite/lib/index.css';
import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/react-editor';
import './styles.css';


import { IKImage, IKContext, IKUpload } from 'imagekitio-react'


import { ThreeDots } from 'react-loader-spinner'
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import Sidebar from '../Sidebar';
import Cookies from 'js-cookie';
import swal from 'sweetalert';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { convertToSlug } from '../../common/global';
const { API_URL } = process.env;

// const mdParser = new MarkdownIt();
// const PLUGINS = undefined;//['header', 'font-bold', 'font-italic', 'block-quote', 'table', 'image', 'link', 'list-unordered', 'list-ordered', 'divider', 'clear', 'mode-toggle'];

const configuration = new Configuration({
    organization: process.env.REACT_APP_GPT_PROJECT_ID,
    apiKey: process.env.REACT_APP_GPT_API_KEY
});


function Home(props) {
    const { setIsOpen } = useTour()
    const editor = useRef(null);

    let navigate = useNavigate();
    const { id } = useParams();
    const userId = Cookies.get('userId');
    const siteId = Cookies.get('siteId');
    const siteStatusSaved = Cookies.get('siteStatus');
    const siteUrl = Cookies.get('siteUrl');
    const [siteStatus, setSiteStatus] = useState(siteStatusSaved);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [authors, setAuthors] = useState([]);
    const [selectedAuthor, setSelectedAuthor] = useState([]);
    const [post, setPost] = useState({});
    const [postslug, setPostSlug] = useState(undefined);
    const [isPublish, setIsPublish] = useState(false);
    const [image, setImageData] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [wpUrl, setWPUrl] = useState("");
    const [initData, setInitData] = useState("<p></p>")
    const [gptText, setGPTText] = useState("");

    const publicKey = "public_8eNVs9M/QaF/laC4piz6UhktEFk=";
    const urlEndpoint = "https://ik.imagekit.io/fcd5oxgok";

    useEffect(() => {
        (async () => {
            setIsPublish(true);
            //Category
            let categories = [], authors = [];
            let resCategory = await fetch(`${process.env.REACT_APP_API_URL}activecategorybyuser/${userId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            let resultCategory = await resCategory.json();
            if (resultCategory.success) {
                categories = resultCategory.data;
                setCategories(resultCategory.data);
            }
            //Author
            let resAuthor = await fetch(`${process.env.REACT_APP_API_URL}activeauthorbyuser/${userId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            let resultAuthor = await resAuthor.json();
            if (resultAuthor.success) {
                authors = resultAuthor.data;
                setAuthors(resultAuthor.data);
            }
            getSiteStatus()
            //Post edit mode
            if (id) {
                let res = await fetch(`${process.env.REACT_APP_API_URL}posts/${userId}/${id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });

                let result = await res.json();
                if (result.success) {
                    setPost(result.data);
                    setPostSlug(result.data.slug);
                    editor.current.getInstance().setMarkdown(result.data.post, true);
                    if (result.data.categoryId && categories && categories.length > 0)
                        setSelectedCategory(categories.filter(a => a._id == result.data.categoryId));
                    if (result.data.authorId && authors && authors.length > 0)
                        setSelectedAuthor(authors.filter(a => a._id == result.data.authorId));
                }
            }
            setIsPublish(false);
            setIsLoading(false);
            if (!Cookies.get('siteTutorialCookie')) {
                setIsOpen(true);
            }
            else {
                document.cookie = `siteTutorialCookie=true;max-age=` + (new Date()).getTime() + (10 * 365 * 24 * 60 * 60);
            }

        })();
    }, []);


    const onError = err => {
        setIsLoading(false);
        console.log("Error", err);
    };

    const onSuccess = res => {
        setIsLoading(false);
        console.log("Success", res);
        setPost(prevState => ({
            ...prevState,
            featuredImage: res.url
        }));
    };

    const showloading = () => {
        setIsLoading(true);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPost(prevState => ({
            ...prevState,
            [name]: value
        }));
        if (name == "title") {
            setPost(prevState => ({
                ...prevState,
                slug: convertToSlug(value)
            }));
        }
    };

    const handleChangeFile = async (e) => {
        const file = e.target.files[0];
        const fileStr = await convertFileToBase64(file);

        setPost(prevState => ({
            ...prevState,
            featuredImage: fileStr
        }));
    };

    function convertFileToBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            // Typescript users: use following line
            // reader.onload = () => resolve(reader.result as string);
            reader.onerror = reject;
        });
    }

    const handleChangeGPTText = (e) => {
        let gpt = e.target.value;
        setGPTText(gpt);
    };

    const handleKeyDownGPTText = async (e) => {
        if (e.key === 'Enter') {
            setIsLoading(true);
            const openai = new OpenAIApi(configuration);
            const response = await openai.createCompletion({
                model: "text-davinci-003",
                prompt: gptText,
                max_tokens: 500,
                temperature: 0,
            });
            let textGenerated = response?.data?.choices[0]?.text;
            setIsLoading(false);
            if (textGenerated) {
                editor.current.getInstance().insertText(textGenerated);
            }
        }
    }

    const handleChangeCategory = (value) => {
        console.log("value selected", value);
        let selectedValue = value[0];
        if (selectedValue?.customOption) {
            //Create new category
            setIsPublish(true);
            createNewCategory(selectedValue);
        }
        if (selectedValue && selectedValue._id) {
            setPost(prevState => ({
                ...prevState,
                categoryId: selectedValue._id
            }));
        }
        setSelectedCategory(value);
    }

    const createNewCategory = async (obj) => {
        let cat = {
            name: obj.name,
            slug: convertToSlug(obj.name),
            isActive: true
        }
        cat.userId = userId;
        cat.siteId = siteId;
        // category.isActive = isActive;
        fetch(`${process.env.REACT_APP_API_URL}categories`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(cat)
        }).then(res => res.json()).then(result => {
            console.log(result);
            if (result.success) {
                setCategoryAfterNew(result.data);
            }
        })
    }
    const setCategoryAfterNew = async (cat) => {
        console.log("created Category", cat);
        let resCategory = await fetch(`${process.env.REACT_APP_API_URL}activecategorybyuser/${userId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        let resultCategory = await resCategory.json();
        if (resultCategory.success) {
            setCategories(resultCategory.data);
            if (cat._id && resultCategory.data && resultCategory.data.length > 0) {
                setSelectedCategory(resultCategory.data.filter(a => a._id == cat._id));
                setPost(prevState => ({
                    ...prevState,
                    categoryId: cat._id
                }));
                setIsPublish(false);
            }
        }
    }
    const handleChangeAuthor = (value) => {
        console.log("value selected", value);
        let selectedValue = value[0];
        if (selectedValue?.customOption) {
            //Create new category
            setIsPublish(true);
            createNewAuthor(selectedValue);
        }
        if (selectedValue && selectedValue._id) {
            setPost(prevState => ({
                ...prevState,
                authorId: selectedValue._id
            }));
        }
        setSelectedAuthor(value);
    }
    const createNewAuthor = async (obj) => {
        let aut = {
            name: obj.name,
            slug: convertToSlug(obj.name),
            isActive: true
        }
        aut.userId = userId;
        aut.siteId = siteId;
        // category.isActive = isActive;
        fetch(`${process.env.REACT_APP_API_URL}authors`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(aut)
        }).then(res => res.json()).then(result => {
            console.log(result);
            if (result.success) {
                setAuthorAfterNew(result.data);
            }
        })
    }
    const setAuthorAfterNew = async (aut) => {
        console.log("created Author", aut);
        let resAuthor = await fetch(`${process.env.REACT_APP_API_URL}activeauthorbyuser/${userId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        let resultAuthor = await resAuthor.json();
        if (resultAuthor.success) {
            setAuthors(resultAuthor.data);
            if (aut._id && resultAuthor.data && resultAuthor.data.length > 0) {
                setSelectedAuthor(resultAuthor.data.filter(a => a._id == aut._id));
                setPost(prevState => ({
                    ...prevState,
                    authorId: aut._id
                }));
                setIsPublish(false);
            }
        }
    }

    const onChangeBlogWrite = (e) => {
        let text = editor.current.getInstance().getHTML();
        console.log("text", text);
        // const regex = /(<([^>]+)>)/ig;
        // text = text.replace(regex, '');
        // console.log("text", text);
        setPost(prevState => ({
            ...prevState,
            post: text
        }));
    }
    const handleImageUpload = (file) => {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.onload = data => {
                console.log(data.target.result);
                // resolve(data.target.result);
                resolve("https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cGVyc29ufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60")
            };
            reader.readAsDataURL(file);
        });
    };

    const onCustomImageUpload = (event) => {
        console.log('onCustomImageUpload', event);
        return new Promise((resolve, reject) => {
            const result = window.prompt('Please enter image url here...');
            resolve({ url: result });
        });
    };

    const revalidate = (post) => {
        fetch(`${siteUrl}/api/revalidate?url=${encodeURI("/post/" + post)}`);
        fetch(`${siteUrl}/api/revalidate?url=/`);
    }
    const getSiteStatus = async (force = false) => {
        if (!siteStatus || force) {
            setSiteStatus(undefined);
            if (siteId) {
                let resStatus = await fetch(`${process.env.REACT_APP_API_URL}sites/getsitestatus/${siteId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                let result = await resStatus.json();
                if (result.success)
                    document.cookie = `siteStatus=true;max-age=` + 60 * 60 * 5;
                setSiteStatus(result.success);
            }
        }

    }
    const onSave = async () => {
        console.log("post", post);
        if (post) {
            setIsLoading(true);
            if (post._id && post.slug != postslug) {
                //Check post is exist by this slug or not
                let postExists = await fetch(`${process.env.REACT_APP_API_URL}postexistbyslug/${siteId}/${post.slug}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                let resultpostexists = await postExists.json();
                console.log("resultpostexists", resultpostexists);
                if (resultpostexists.success) {
                    swal("Warning", `Post slug is already exists. Try entering different title.`, "warning");
                    setIsLoading(false);
                    return;
                }
            }
            post._id = id ? id : post._id;
            post.userId = userId;
            post.siteId = siteId;
            fetch(`${process.env.REACT_APP_API_URL}posts/save`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(post)
            }).then(res => res.json()).then(result => {
                if (result.success) {
                    $("#right-sidebar").removeClass("open");
                    setIsLoading(false);
                    setPost(result.data);
                    setPostSlug(result.data.slug);
                    revalidate(result.data.slug);
                    swal("Submitted", `Post saved successfully.`, "success");

                    setTimeout(() => {
                        window.location = "/articles";
                    }, 2000);
                }
            }).catch((error) => {
                console.log(error)
            });

        }
    }

    const onDelete = async () => {
        if (window.confirm("Are you sure want to delete this article?")) {
            if (post) {
                setIsLoading(true);
                //Delete article
                fetch(`${process.env.REACT_APP_API_URL}posts/delete/${userId}/${id}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    // body: JSON.stringify({})
                }).then(res => res.json()).then(result => {
                    console.log("result", result);
                    if (result.success) {
                        $("#right-sidebar").removeClass("open");
                        setIsLoading(false);
                        swal("Submitted", `Post deleted successfully.`, "success");
                        fetch(`${siteUrl}/api/revalidate?url=/`);
                        setTimeout(() => {
                            window.location = "/articles";
                        }, 2000);

                    }
                }).catch((error) => {
                    console.log(error)
                });
            }
        }
    }

    const navigateURL = () => {
        if (post && post.slug) {
            window.open(siteUrl + "/post/" + post.slug, "_blank")
        }
    }

    const importFromWordpress = async () => {
        if (wpUrl) {
            const { articleTitle, articleContent, articleSlug } = await fetchArticleContent(wpUrl);
            console.log('Article Title:', articleTitle);
            console.log('Article Content:', articleContent);
            editor.current.getInstance().setHTML(articleContent);
            setPost(prevState => ({ ...prevState, title: articleTitle, slug: articleSlug }));

        }
    }

    async function fetchArticleContent(articleUrl) {
        try {
            const urlHost = new URL(articleUrl);
            const articleSlug = articleUrl.match(/\/([^/]+)\/?$/)[1];
            const apiSlugUrl = `https://${urlHost.host}/wp-json/wp/v2/posts?slug=${articleSlug}`;

            const slugResponse = await fetch(apiSlugUrl);
            if (!slugResponse.ok) {
                throw new Error('Failed to fetch article ID.');
            }

            const articleData = await slugResponse.json();
            if (Array.isArray(articleData) && articleData.length > 0) {
                const articleId = articleData[0].id;

                const apiUrl = `https://${urlHost.host}/wp-json/wp/v2/posts/${articleId}`;
                const response = await fetch(apiUrl);

                if (!response.ok) {
                    throw new Error('Failed to fetch article.');
                }

                const articleContentData = await response.json();
                const articleTitle = articleContentData.title.rendered;
                const articleContent = articleContentData.content.rendered;

                return { articleTitle, articleContent, articleSlug };
            } else {
                throw new Error('Article not found.');
            }
        } catch (error) {
            throw error;
        }
    }

    function countWords(str) {
        str = str.replace(/(^\s*)|(\s*$)/gi, '');
        str = str.replace(/[ ]{2,}/gi, ' ');
        str = str.replace(/\n /, '\n');
        return str.split(' ').length;
    }

    // const onClose = () => {
    //     navigate("/articles");
    // }
    return (
        <div class="container-fluid page-body-wrapper">
            <Sidebar active="home" />
            <div class="main-panel">

                <div className='flex-row block' style={{ marginTop: 5 }}>
                    <div className='status site-status'>
                        {siteStatus == undefined ? "Loading your site deployment status..." : (siteStatus ?
                            <a href={siteUrl} target="_blank">Site is ready: {siteUrl}</a> :
                            <div className='row'>Site is not ready yet. Please wait...
                                <div style={{ marginLeft: 10 }} onClick={getSiteStatus(true)}> <i class="ti-reload"></i></div>
                            </div>)
                        }
                    </div>
                    <div className="button setting-button">
                        <div class="nav-settings">
                            <div class="p-2" href="#">
                                <i class="ti-settings display-4"></i>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="col-lg-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="row mb-2">
                                <div class="col">
                                    <input type="text" class="form-control" placeholder="Enter Wordpress Article URL" name="wpUrl" value={wpUrl} onChange={(e) => setWPUrl(e.target.value)} />
                                </div>
                                <div class="col">
                                    <button type='button' className='btn btn-primary' onClick={() => importFromWordpress()}>Import from Wordpress</button>
                                </div>
                            </div>
                            {/* <input type="text" class="form-control" placeholder="Generate Article by text: ex. Write an article about Monalisa Painting. ⏎" name="textgptwrite" value={gptText} onChange={handleChangeGPTText} onKeyDown={handleKeyDownGPTText} /> */}
                            {/* <textarea id="writer" placeholder="Start writing..." className="writer" autoFocus="" spellCheck="false" name="post" onChange={handleChange} value={post?.post}></textarea> */}
                            {/* <MdEditor ref={mdEditor}
                                plugins={PLUGINS}
                                config={{
                                    view: {
                                        menu: true,
                                        md: true,
                                        html: true,
                                        fullScreen: true,
                                        hideMenu: true,
                                    },
                                    table: {
                                        maxRow: 5,
                                        maxCol: 6,
                                    },
                                    imageUrl: 'https://octodex.github.com/images/minion.png',
                                    syncScrollMode: ['leftFollowRight', 'rightFollowLeft'],
                                }}
                                onImageUpload={handleImageUpload}
                                //onCustomImageUpload={onCustomImageUpload}
                                onFocus={e => console.log('focus', e)}
                                onBlur={e => console.log('blur', e)}
                                style={{ height: '90%', width: '100%' }}
                                shortcuts={true} placeholder={"Start writing here..."}
                                renderHTML={text => mdParser.render(text)} onChange={onChangeBlogWrite} value={post?.post} readOnly={isLoading} /> */}

                            <Editor
                                usageStatistics={false}
                                ref={editor}
                                previewHighlight={false}
                                initialValue={initData}
                                previewStyle="vertical"
                                height="75vh"
                                // onFocus={() => { $("#right-sidebar").removeClass("open"); }}
                                onChange={onChangeBlogWrite}
                                initialEditType="wysiwyg"
                                useCommandShortcut={true}
                                placeholder={"Start writing here..."}
                                readOnly={isLoading}
                                hideModeSwitch={true}
                                toolbarItems={[
                                    ['heading', 'bold', 'italic', 'strike'],
                                    ['hr', 'quote'],
                                    ['ul', 'ol', 'indent', 'outdent'],
                                    ['table', 'image', 'link'],
                                    // ['code', 'codeblock'],
                                    ['scrollSync'],
                                ]}
                            />

                            {isLoading ?
                                <div className='loading'>
                                    <ThreeDots type="ThreeDots" color="#2BAD60" height="70" width="70" />
                                </div>
                                : null}

                            {/* <div className='notebottom'>Note: Currenly, your changes will be reflect on your site within 5 to 10 sec. Instant update feature is coming soon.</div> */}
                        </div>
                    </div>
                </div>

                <div id="right-sidebar" class="settings-panel">
                    <i class="settings-close ti-close"></i>
                    <ul class="nav nav-tabs border-top" id="setting-panel" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="todo-tab" data-toggle="tab" href="#todo-section" role="tab" aria-controls="todo-section" aria-expanded="true">Settings</a>
                        </li>
                    </ul>
                    <div class="tab-content" id="setting-content">
                        <div class="tab-pane list-wrapper fade show active scroll-wrapper" id="todo-section" role="tabpanel" aria-labelledby="todo-section">
                            <div class="add-items d-flex px-3 mb-0">
                                <form class="form w-100">
                                    <div class="form-group d-flex justify-content-around">
                                        <button type="button" class="public-button add btn btn-primary todo-list-add-btn" id="add-task" onClick={onSave} disabled={isPublish}>Publish</button>
                                        <button type="button" class="add btn btn-primary todo-list-add-btn" id="add-task" onClick={navigateURL}>Link</button>
                                    </div>
                                    <div className='notebottom' style={{ textAlign: 'center', marginBottom: 20 }}>Your changes will reflect instantly.</div>
                                </form>
                            </div>
                            <div class="px-1">
                                <div class="px-1 mb-0">
                                    <div class="form-group">
                                        <label for="blogTitle">Title</label>
                                        <input type="text" class="form-control" id="blogTitle" placeholder="Enter blog title" value={post?.title} name="title" onChange={handleChange} />
                                    </div>
                                    <div class="form-group">
                                        <label for="blogTitle">Slug</label>
                                        <input type="text" class="form-control" id="blogTitle" placeholder="Your slug" value={post?.slug} name="slug" disabled />
                                    </div>
                                    <div class="form-group">
                                        <label for="blogDescription">Description</label>
                                        <textarea type="text" class="form-control" id="blogDescription" placeholder="Enter description" value={post?.description} name="description" onChange={handleChange} />
                                    </div>

                                    <div class="form-group">
                                        {/* <label for="blogTitle">Feature Image(1200X690px):</label>
                                        <input type="file" class="form-control" id="blogFeatureImage" placeholder="Select Featured Image" name="featuredImage" onChange={handleChangeFile} />
                                        {post?.featuredImage ? <img src={post.featuredImage} width={300} /> : null} */}

                                        {/* <IKContext urlEndpoint={urlEndpoint} publicKey={publicKey} authenticationEndpoint={`{process.env.REACT_APP_API_URL}auth/imageverify`}>
                                            <IKUpload
                                                fileName="file-name.jpg"
                                                tags={["sample-tag1", "sample-tag2"]}
                                                customCoordinates={"10,10,10,10"}
                                                isPrivateFile={false}
                                                useUniqueFileName={true}
                                                responseFields={["tags"]}
                                                // folder={"/sample-folder"}
                                                // inputRef={uploadRef}

                                                
                                            />
                                        </IKContext> */}

                                        <IKContext
                                            urlEndpoint={urlEndpoint}  // Required. Default URL-endpoint is https://ik.imagekit.io/your_imagekit_id
                                            publicKey={publicKey}
                                            transformationPosition="path" // optional
                                            authenticationEndpoint="https://write-app-api.onrender.com/api/auth/imageverify">
                                            <IKUpload
                                                onUploadStart={showloading}
                                                onSuccess={onSuccess}
                                                onError={onError}
                                                useUniqueFileName={false} />
                                            {post?.featuredImage ? <IKImage src={post.featuredImage} width={250} style={{ alignSelf: 'center' }} /> : null}
                                        </IKContext>

                                    </div>
                                    <div class="form-group">
                                        <label for="blogCategory">Category</label>



                                        <Typeahead
                                            allowNew={true}
                                            id="basic-typeahead-single"
                                            labelKey="name"
                                            newSelectionPrefix={"Add Category: "}
                                            onChange={handleChangeCategory}
                                            options={categories}
                                            placeholder="Choose or type a category..."
                                            selected={selectedCategory}
                                        />

                                    </div>
                                    <div class="form-group">
                                        <label for="blogAuthor">Author</label>

                                        <Typeahead
                                            allowNew={true}
                                            id="basic-typeahead-single"
                                            labelKey="name"
                                            newSelectionPrefix={"Add Author: "}
                                            onChange={handleChangeAuthor}
                                            options={authors}
                                            placeholder="Choose or type a author..."
                                            selected={selectedAuthor}
                                        />


                                    </div>
                                    <div class="form-group">
                                        {post.pubDate ? <h4 class="px-3 text-muted mt-5 font-weight-light mb-0">Last Published</h4> : null}
                                        {post.pubDate ?
                                            <div class="events pt-4 px-3">
                                                <div class="wrapper d-flex mb-2">
                                                    <i class="ti-control-record text-primary mr-2"></i>
                                                    <span>
                                                        <Moment format="MM/DD/YYYY hh:mm A" local>
                                                            {post?.pubDate}
                                                        </Moment>
                                                    </span>
                                                </div>

                                            </div>
                                            : null}
                                    </div>
                                    <div class="form-group">
                                        <button type="button" class="public-button add btn btn-primary todo-list-add-btn" id="delete-article" onClick={onDelete} disabled={isPublish}>Delete Article</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    );
}

export default Home;